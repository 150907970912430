import React from 'react'

import Feed from '~/components/feed/Feed'

function Relates({posts}) {
  if (posts.range.data.length === 0) {
    return null
  }

  return (
    <div
      data-testid="post/Relates">
      <div className="container xl:max-w-screen-lg">
        <h2 className="mb-6">
          <span className="font-fc text-content flex-shrink-0 mr-2 font-bold">You Might Also Like</span>
        </h2>
      </div>
      <Feed
        posts={posts}
        onLoadMore={() => {}} />
    </div>
  )
}

export default Relates
