import React from 'react'
import LinkifyIt from 'linkify-it'

import AdUnit from '~/components/AdUnit'

const linkify = LinkifyIt()

function prepareHTMLs(postContent) {
  return postContent
    // remove post_id tag
    .replace(/^\[\d+]\.?$/m, '')
    // remove all tag attributes except img, iframe, br
    .replace(/<\/?([^ />]+).*?>/g, (original, tag) => {
      switch (tag) {
        case 'a':
        case 'img':
        case 'iframe':
        case 'br':
          return original
        default:
          return ''
      }
    })
    // handle image
    .replace(/<img.+?src="(.+?)".*?\/>/g, (_, img) => {
      const imgOriginal = img.replace(/-\d+x\d+(\.\w+)$/, '$1')

      return `<img src="${imgOriginal}" class="img-fluid"/>`
    })
    // handle link
    .replace(/<a.+?href=(['"])(.*?)\1.*?>(.+?)<\/a>/g, (original, _, href, text) => `<a href="${href}" target="_blank" rel="nofollow">${text}</a>`)
    // handle caption
    .replace(/\[caption.+?]<img.+?src="(.+?)".*?\/>(.*?)\[\/caption]/g, (_, img, caption) => `<img src="${img}" class="img-fluid d-block"/><small>${caption}</small>`)
    .split(/\r?\n\r?\n/)
    // handle NEWLINE display in HTML
    .map(paragraph => paragraph.trim().split(/\r\n/).join('<br/>'))
    .map(paragraph => paragraph.trim().split(/\n/).join('<br/>'))
    // handle youtube or iframe with witdh/height
    .map(paragraph => {
      if (typeof paragraph !== 'string') return paragraph

      const m = (/<iframe(.+?)>.*?<\/iframe>/).exec(paragraph)

      if (m === null) return paragraph

      try {
        const width = parseFloat((/width="(\d+?)"/).exec(m[1])[1])
        const height = parseFloat((/height="(\d+?)"/).exec(m[1])[1])
        const src = (/src="(.+?)"/).exec(m[1])[1]
        const style = {paddingBottom: `${100 * height / width}%`}

        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(width) && !isNaN(height) && src !== null && src.length > 0) {
          return (
            <div
              className="position-relative"
              style={style}>
              <iframe
                className="position-absolute border-0 w-100 h-100"
                src={src} />
            </div>
          )
        }
      }
      catch (e) {
        return paragraph
      }
    })
    // handle facebook
    .map(paragraph => {
      if (typeof paragraph !== 'string') return paragraph

      const m = (/^(https?:\/\/www\.facebook\.com\/[^/]+?\/videos\/[\d]+?\/)$/).exec(paragraph)

      if (m === null) return paragraph

      const width = 800

      return (
        // eslint-disable-next-line react/jsx-key
        <div style={{maxWidth: width}}>
          <div
            className="fb-video"
            data-href={m[1]}
            data-width="800"
            data-show-text="false" />
        </div>
      )
    })
    .map(paragraph => {
      if (typeof paragraph !== 'string') return paragraph
      if (!linkify.test(paragraph)) return paragraph

      // linkify
      const matches = linkify.match(paragraph)
      const chunks = []
      let i = 0

      for (; i < matches.length; i++) {
        const m = matches[i]

        if (i === 0) {
          chunks.push(paragraph.slice(0, m.index))
        }
        else {
          chunks.push(paragraph.slice(matches[i - 1].lastIndex, m.index))
        }

        // ignore link in <img> tag
        if (paragraph.slice(m.index - 10, m.index) === '<img src="') {
          chunks.push(paragraph.slice(m.index, m.lastIndex))
        }
        // ignore link in <a> tag
        else if (paragraph.slice(m.index - 9, m.index) === '<a href="') {
          chunks.push(paragraph.slice(m.index, m.lastIndex))
        }
        else if ((chunks.join('').match(/<a|<\/a>/g) || []).length % 2 === 0) {
          chunks.push(`<a href="${m.url}" target="_blank" rel="nofollow">${m.raw}</a>`)
        }
        else {
          chunks.push(paragraph.slice(m.index, m.lastIndex))
        }
      }
      chunks.push(paragraph.slice(matches[i - 1].lastIndex))

      return chunks.join('')
    })
}

function insertAds(preparedHtmls, targets) {
  let consecutiveNonMedias = 0
  let offset = 0

  const nonMedia = html => typeof html === 'string' && !html.includes('<img')

  return preparedHtmls.reduce((z, html) => {
    if (nonMedia(html)) {
      consecutiveNonMedias++

      if (consecutiveNonMedias > 24 && nonMedia(z[z.length - 1])) {
        consecutiveNonMedias = 0
        offset++

        return [
          ...z,
          <AdUnit
            key={`ad-${offset}`}
            name="bc-in-read"
            targets={{offset, ...targets}}
            collapsible />,
          html,
        ]
      }
    }

    return [...z, html]
  }, [])
}

function Content({post}) {
  if (post.post_content === null || post.post_content.length === 0) {
    return null
  }

  const preparedHtmls = prepareHTMLs(post.post_content)
  const categories = post.categories.range.data.map(category => category.term_id)
  const htmls = insertAds(preparedHtmls, {categories})

  return (
    <React.Fragment>
      {htmls.map((html, i) => (
        typeof html === 'string'
          ? <div
            className="block"
            key={`post-${post.id}-block-${i}`}
            dangerouslySetInnerHTML={{__html: html}} />
          : <div
            className="block"
            key={`post-${post.id}-block-${i}`}>{html}</div>
      ))}
      <style
        jsx
        global>{`
        .block {
          word-wrap: break-word;
          overflow-wrap: anywhere;
          margin-bottom: 1rem;
          &:last-child {
            margin-bottom: 0;
          }
          & .img-fluid {
            max-width: 100%;
            height: auto;
          }
          & .d-block {
            display: block;
          }
          & .position-relative {
            position: relative;
          }
          & .position-absolute {
            position: absolute;
          }
          & .border-0 {
            border: 0;
          }
          & .w-100 {
            width: 100%;
          }
          & .h-100 {
            height: 100%;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

export default Content
