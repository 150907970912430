import gql from '~/modules/graphql/gql'
import fragments from './fragments'

export default gql`
  query Post($id: Int!) {
    post(id: $id) {
      ...PostFragment
      post_content
      categories {
        range {
          data {
            term_id
            name
          }
        }
      }
      tags {
        data {
          term_id
          name
          slug
        }
      }
      related_posts {
        range(limit: 8) {
          data {
            ...PostFragment
          }
        }
      }
    }
  }

  ${{fragments}}
`
