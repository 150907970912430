import React from 'react'

export default function Alert({children, ...props}) {
  return (
    <div
      className="flex items-center bg-warning px-3 py-2 rounded-sm font-normal"
      {...props}>
      {children}
    </div>
  )
}