import React from 'react'

import {useTranslation} from '~/i18n'
import Thumbnail from './post/Thumbnail'
import Content from './post/Content'
import Tags from './post/Tags'

export default function PostFull({post}) {
  const {t} = useTranslation('datetime')

  return (
    <div
      className="text-content overflow-hidden bg-primary sm:rounded-2xl px-4 md:px-0"
      data-testid="PostFull">
      <Thumbnail
        post={post}
        hd
        nolazy />
      <div className="py-4">
        {post.post_title && (
          <h1
            className="text-2xl font-fc font-bold"
            data-testid="PostFull/title">{post.post_title}</h1>
        )}
        <div className="mt-4 text-sm font-fc">
          {t('date.short', {date: new Date(post.post_date)})}
        </div>
        <div className="mt-4 text-base">
          <Content post={post} />
        </div>
        <div className="mt-4">
          <Tags post={post} />
        </div>
      </div>
    </div>
  )
}
