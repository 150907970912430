import React from 'react'

import LogoBrand from '~/components/LogoBrand'

function Contact() {
  return (
    <div
      className="overflow-hidden bg-nav rounded-xl p-8"
      data-testid="Contact">
      <div className="flex justify-start text-2xl">
        <LogoBrand />
      </div>
      <div className="my-6 text-true-white font-fc">
        <p>สนใจโฆษณาติดต่อที่</p>
        <a href="mailto:contact@brandcase.co">contact@brandcase.co</a>
      </div>
      <div className="flex justify-evenly mt-8">
        {[
          {icon: 'bc-ic-facebook', href: 'https://www.facebook.com/BrandCaseTH'},
          {icon: 'bc-ic-blockdit', href: 'https://www.blockdit.com/brandcase '},
          {icon: 'bc-ic-instagram', href: 'https://instagram.com/brandcaseth'},
          {icon: 'bc-ic-twitter', href: 'https://twitter.com/brandcaseth'},
          {icon: 'bc-ic-youtube', href: 'https://www.youtube.com/channel/UCIJa-0_9Brk9SneuzD-RtbQ'},
        ].map(({icon, href}) => (
          <a
            key={icon}
            className="text-secondary text-2xl hover:text-secondary"
            target="_blank"
            rel="noopener noreferrer"
            href={href}>
            <i className={icon} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default Contact
