import React from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {useQuery} from '@apollo/client'

import config from '~/config'
import {useTranslation} from '~/i18n'
import Layout from '~/layouts/default'
import qlPost from '~/graphql/Post'

import PostFull from '~/components/PostFull'
import Relates from '~/components/post/Relates'
import Contact from '~/components/Contact'
import Alert from '~/components/Alert'
import AdUnit from '~/components/AdUnit'
import {PageNotFound} from '~/exception'
import {isInt} from '~/utils/validate'

function isValidSecret(id, secret) {
  if (typeof secret === 'undefined') {
    return false
  }

  const sum = `${id}`
    .split('')
    .map(a => parseInt(a, 10))
    .reduce((z, a) => z + a)

  return sum * sum + 1 === parseInt(secret, 10)
}

function validateId(id) {
  if (!isInt(id)) {
    throw new PageNotFound()
  }
}

function validatePost(post, id, secret) {
  if (post === null || !['future', 'publish'].includes(post.post_status)) {
    throw new PageNotFound()
  }
  if (post.post_status === 'future') {
    if (!isValidSecret(id, secret)) {
      throw new PageNotFound()
    }
  }
}

export function usePost(id, secret) {
  validateId(id)

  const {loading, data} = useQuery(qlPost, {variables: {id: parseInt(id, 10)}})

  const ready = !loading && typeof data !== 'undefined'

  if (!ready) {
    return {ready}
  }

  const {post} = data

  validatePost(post, id, secret)

  return {ready, post}
}

export default function PagePostOne() {
  const router = useRouter()
  const {t} = useTranslation('post')

  const {ready, post} = usePost(router.query.id, router.query.t)

  if (!ready) {
    return null
  }

  const canonicalUrl = `${config.baseurl}/${post.id}`
  const title = post.post_title
  const description = post.post_excerpt.replace(/(\r\n|\n|\r)/gm, ' ')
  const ogImageUrl = post.thumbnail && encodeURI(post.thumbnail.src)

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        <meta
          key="description"
          name="description"
          content={description} />
        <link
          rel="canonical"
          href={canonicalUrl} />

        <meta
          key="og:url"
          property="og:url"
          content={canonicalUrl} />
        <meta
          key="og:type"
          property="og:type"
          content="article" />
        <meta
          key="og:title"
          property="og:title"
          content={title} />
        <meta
          key="og:description"
          property="og:description"
          content={description} />

        <meta
          key="article:published_time"
          property="article:published_time"
          content={post.post_date} />
        {post.tags.data.map(tag => (
          <meta
            key={`article:tag:${tag.slug}`}
            property="article:tag"
            content={tag.name} />
        ))}
        {post.categories.range.data.length > 0 &&
          <meta
            key="article:section"
            property="article:section"
            content={post.categories.range.data[0].name} />
        }
        {post.thumbnail && (
          <React.Fragment>
            <meta
              key="og:image"
              property="og:image"
              content={ogImageUrl} />
            <meta
              key="og:image:width"
              property="og:image:width"
              content={post.thumbnail.width} />
            <meta
              key="og:image:height"
              property="og:image:height"
              content={post.thumbnail.height} />
            <meta
              key="twitter:image"
              name="twitter:image"
              content={ogImageUrl} />
          </React.Fragment>
        )}
        <meta
          key="twitter:card"
          name="twitter:card"
          content="summary" />
        <meta
          key="twitter:title"
          name="twitter:title"
          content={title} />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={description} />
      </Head>
      <div
        className="container xl:max-w-screen-lg"
        data-testid="pages/[id]">
        {post.post_status === 'future' && (
          <div className="mb-4">
            <Alert data-testid="pages/[id]/alertPostFuture">
              {t('alert.post_future', {datetime: new Date(post.post_date)})}
            </Alert>
          </div>
        )}
        <div className="flex">
          <div className="min-w-0 flex-grow -mx-4 sm:-mx-0">
            <article>
              <PostFull post={post} />

            </article>
          </div>
          <div
            className="flex-grow-0 flex-shrink-0 ml-4 hidden md:block"
            style={{width: 300}}>
            <aside>
              <div
                className="mb-4"
                style={{height: 250}}>
                <AdUnit name="bc-rectangle-a" />
              </div>
              <div className="mb-4">
                <Contact />
              </div>
              <div
                className="mb-4"
                style={{height: 250}}>
                <AdUnit name="bc-rectangle-c" />
              </div>
            </aside>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <aside>
          <Relates posts={post.related_posts} />
        </aside>
      </div>
    </React.Fragment>
  )
}
PagePostOne.Layout = Layout
PagePostOne.getApolloQueryProperties = ({ctx}) => {
  validateId(ctx.query.id)

  return {
    query: qlPost,
    variables: {id: parseInt(ctx.query.id, 10)},
    callback: res => {
      const {post} = res.data

      validatePost(post, ctx.query.id, ctx.query.t)
    },
  }
}

export const getServerSideProps = async ({res}) => {
  res.setHeader('Cache-Control', 'max-age=60')

  return {props: {}}
}
