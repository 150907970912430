import React from 'react'
import Link from 'next/link'

import {sendGtagEvent} from '../../modules/ga'

function Tags({post}) {
  if (post.tags.data.length === 0) {
    return null
  }


  return (
    <React.Fragment>
      <div className="flex flex-wrap items-center">
        <span className="mr-2">Tag:</span>
        {post.tags.data.map(({slug, name}, i) => (
          <Link
            key={`tag-${slug}`}
            href="/tag/[slug]"
            as={`/tag/${slug}`}
            prefetch={false}>
            <a
              className="border-tag text-tag hover:bg-tag hover:text-white border rounded-full px-4 py-1 font-normal mr-2 my-1 transition-colors ease-in-out duration-75"
              data-testid={`post/Tags/tag-offset-${i}`}
              onClick={() => sendGtagEvent('click', {category: 'post/Tags', label: `tag-offset-${i}`})}>
              {name}
            </a>
          </Link>
        ))}
      </div>
    </React.Fragment>
  )
}

export default Tags
